import React from 'react'

import {
	RiMastercardLine,
	RiVisaLine,
	RiPaypalLine
} from 'react-icons/ri'

export default function PaymentMethod( props ) {

	const { setPaymentMethod } = props

	return (
		<div className="payment-method">
			<ul className="payment-method__list">
				<li className="payment-method__item">
					<input type="radio" className="payment-method__input" name="paymentMethod" id="paymentPaypal" value="paypal" onChange={ (e) => setPaymentMethod( e.target.value ) } />
					<label className="payment-method__label" htmlFor="paymentPaypal">
						<div className="paymenth-method__icons-container">
							<RiPaypalLine className="payment-method__icon" />
						</div>
						Paypal
					</label>
				</li>
				<li className="payment-method__item">
					<input type="radio" className="payment-method__input" name="paymentMethod" id="card" value="card" onChange={ (e) => setPaymentMethod( e.target.value ) } />
					<label className="payment-method__label" htmlFor="card">
						<div className="paymenth-method__icons-container">
							<RiMastercardLine className="payment-method__icon" />
							<RiVisaLine className="payment-method__icon" />
						</div>
						Card
					</label>
				</li>
			</ul>
		</div>
	)
}