import React, { useContext, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { CartContext } from '../../context/CartContext';

import { weightTotal } from "../../helpers/cart"
import { findPostage } from "../../helpers/shipping"
import { formatPrice } from '../../helpers/currency';

export default function Shipping( props ) {

	const { cart, setPostage, shippingState, setShippingState } = useContext( CartContext );
	const data = useStaticQuery(graphql`
		query ShippingQuery {
			strapi {
				shippingZones {
					id
					postages {
						name
						id
						pricing
						shipping_zone {
							id
						}
					}
				}
				countries {
					name
					id
					shipping_zone {
						id
					}
				}
			}
		}`
	)

	const countries = data.strapi.countries
	const shipping = data.strapi.shippingZones
	let totalProductWeight = weightTotal( cart )

	useEffect(() => {
		setPostage({ price: 0 })
	}, [ cart, setPostage ])

	const handleChange = ( event ) => {
		const zoneId = Number(event.target.value)

		findPostage( zoneId, shipping, setShippingState, totalProductWeight )
	}
	const handlePostage = ( event ) => {
		const price = parseFloat( event.target.value )
		const id = parseFloat( event.target.id )

		setPostage( { price, id } )
	}

	return(
		<div className="shipping">
			{ !props.countrySelected &&
				<select className="shipping__select shipping__select--country" defaultValue={ shippingState.zoneId } onChange={ e => handleChange( e ) } onBlur={ e => handleChange( e ) }>
					<option value="0" disabled>Select your Country</option>
					{ countries.map( node => {
						return(
							<option value={ node.shipping_zone.id } key={ node.id }>
								{ node.name }
							</option>
						)
					} ) }
				</select>
			}

			{ !!shippingState.zoneId &&
				<>
					{ shippingState.filteredPostage.length ? (
						<>
						{ shippingState.filteredPostage.map( postage => {
							return (
								<div className="shipping__radio-container" key={ postage.id }>
									<input
										className="shipping__radio-input"
										type="radio"
										name="shipping"
										value={ postage.price }
										id={ postage.id }
										onClick={ (e) => handlePostage(e) } />
									<label className="shipping__radio-label" htmlFor={ postage.id }>
										{ postage.name }
										<span className="shipping__radio-price">{ formatPrice( postage.price ) }</span>
									</label>
								</div>
							)
						} )	}
						</>
					) : (
						<p> please contact us</p>
					) }
				</>
			}
		</div>
	)
}
