import React, { useState } from 'react'

import LoginForm from '../../components/Login/LoginForm'
import RegisterForm from '../../components/Register/RegisterForm'

import { RiArrowDownSLine } from 'react-icons/ri'

export default function LoginOrGuest( props ) {

	const { setGuestCheckout } = props

	const [ returningCustomer, toggleReturningCustomer ] = useState( true )
	const [ newCustomer, toggleNewCustomer ] = useState()
	const [ guest, toggleGuest ] = useState()

	return (
		<>
			<div className="checkout__details-container">

				<h2 className="checkout__header">Checkout</h2>

				<div className="checkout__section">
					<button className="checkout__section-header checkout__section-header--icon" onClick={() => toggleReturningCustomer( !returningCustomer )}>
						Returning Customer
						<RiArrowDownSLine className={`checkout__section-header-icon ${ returningCustomer ? 'active' : ''}`} />
					</button>
					<div className={`checkout__section-container ${ !returningCustomer ? 'disabled' : ''}`}>
						<LoginForm />
					</div>
				</div>

				<div className="checkout__section">
					<button className="checkout__section-header checkout__section-header--icon" onClick={() => toggleNewCustomer( !newCustomer )}>
						New Customer
						<RiArrowDownSLine className={`checkout__section-header-icon ${ newCustomer ? 'active' : ''}`} />
					</button>
					<div className={`checkout__section-container ${ !newCustomer ? 'disabled' : ''}`}>
						<RegisterForm cssClass={ 'form--no-headings' }/>
					</div>
				</div>

				<div className="checkout__section">
					<button className="checkout__section-header checkout__section-header--icon" onClick={() => toggleGuest( !guest )}>
						Checkout as guest
						<RiArrowDownSLine className={`checkout__section-header-icon ${ guest ? 'active' : ''}`} />
					</button>
					<div className={`checkout__section-container ${ !guest ? 'disabled' : ''}`}>
						<button className="button button--center" onClick={ () => setGuestCheckout( true ) }>Proceed with checkout as guest</button>
					</div>
				</div>

			</div>
		</>
	)
}