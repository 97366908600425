import React, { useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { CartContext } from '../../context/CartContext';
import { formatPrice } from '../../helpers/currency';

export default function CheckoutProductList() {

	const { cart } = useContext( CartContext );

	return (
		<>
			{ cart.map( product => (
				<Link to={`/products/${ product.slug }`} className="checkout-product" key={ product.id }>

					<GatsbyImage className="checkout-product__image" image={ product.images[0].localFile.childImageSharp.gatsbyImageData } alt={ product.name } />

					<div className="checkout-product__info">
						<div className="checkout-product__price">{ formatPrice( product.price ) }</div>
						<div className="checkout-product__name">{ product.name }</div>

						<div className="checkout-product__quantity">
							Qty: { product.quantity }
						</div>

					</div>
				</Link>
			) ) }
		</>
	)
}