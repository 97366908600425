import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import { Link } from 'gatsby'

import useAuth from '../../hooks/useAuth'

export default function RegisterForm( props ) {

	const { cssClass } = props

	const form = useRef()
	const { register } = useAuth()

	// Registration
	const [ firstName, setFirstName ] = useState('')
	const [ lastName, setLastName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ addressLine1, setAddressLine1 ] = useState('')
	const [ addressLine2, setAddressLine2 ] = useState('')
	const [ city, setCity ] = useState('')
	const [ postcode, setPostcode ] = useState('')
	const [ country, setCountry ] = useState('')
	const [ region, setRegion ] = useState('')
	const [ phoneNumber, setPhoneNumber ] = useState('')
	const [ password, setPassword ] = useState('')
	const [ confirmPassword, setConfirmPassword ] = useState('')
	const [ passwordMatchError, setPasswordMatchError ] = useState(false)
	const [ error, setError ] = useState('')

	const data = useStaticQuery(graphql`
		query registrationCountries {
			strapi {
				countries {
					name
					id
					shipping_zone {
						id
					}
				}
			}
		}`
	)

	const countries = data.strapi.countries

	const handleCountryChange = ( event ) => {
		setCountry( event.target.value )
	}

	const handleRegistrationSubmit = async ( event ) => {
		event.preventDefault();
		form.current.reportValidity();

		if ( password !== confirmPassword ) {
			setPasswordMatchError( true )
			return;
		} else {
			setPasswordMatchError( false )
		}

		const data = {
			email,
			password: password,
			username: email,
			firstName,
			lastName,
			addressLine1,
			addressLine2,
			city,
			postcode,
			country,
			region,
			phoneNumber
		}

		try {
			await register( data );
		} catch ( error ) {
			console.log("Error occurred during registration and login")
			setError("Error")
		}
	}

	return (
		<form
			className={`form ${ cssClass ? cssClass : '' }`}
			onSubmit={ handleRegistrationSubmit }
			ref={ form }>

			<div className="form__section-heading">
				Your Personal Details
			</div>
			
			<div className="form__input-container">
				<label className="form__label"  htmlFor="firstName">First Name</label>
				<input className="form__input" type="text" name="firstName" id="firstName" value={ firstName } onChange={ e => { setFirstName( e.target.value ) } } required />
			</div>
			<div className="form__input-container">
				<label className="form__label"  htmlFor="lastName">Last Name</label>
				<input className="form__input" type="text" name="lastName" id="lastName" value={ lastName } onChange={ e => { setLastName( e.target.value ) } } required/>
			</div>
			<div className="form__input-container">
				<label className="form__label"  htmlFor="email">Email</label>
				<input className="form__input" type="email" name="email" id="email" value={ email } onChange={ e => { setEmail( e.target.value ) } } required/>
			</div>

			<div className="form__section-heading">
				Your Address
			</div>
			<div className="form__input-container">
				<label className="form__label"  htmlFor="addressLine1">House No/Name</label>
				<input className="form__input" type="text" name="addressLine1" id="addressLine1" value={ addressLine1 } onChange={ e => { setAddressLine1( e.target.value ) } } required/>
			</div>
			<div className="form__input-container">
				<label className="form__label"  htmlFor="addressLine2">Address 2 <span className="form__label-optional">(optional)</span></label>
				<input className="form__input" type="text" name="addressLine2" id="addressLine2" value={ addressLine2 } onChange={ e => { setAddressLine2( e.target.value ) } }/>
			</div>
			<div className="form__input-container">
				<label className="form__label"  htmlFor="city">City</label>
				<input className="form__input" type="text" name="city" id="city" value={ city } onChange={ e => { setCity( e.target.value ) } } required/>
			</div>
			<div className="form__input-container">
				<label className="form__label"  htmlFor="postcode">Postcode</label>
				<input className="form__input" type="text" name="postcode" id="postcode" value={ postcode } onChange={ e => { setPostcode( e.target.value ) } } required/>
			</div>
			<div className="form__input-container">
				<label className="form__label" htmlFor="country">Country</label>
				<select
					className="form__input"
					name="country"
					id="country"
					required
					defaultValue={ country }
					onChange={  e => handleCountryChange( e ) }
					onBlur={  e => handleCountryChange( e ) }>
						<option value="" disabled>Select your Country</option>
						{ countries.map( node => {
							return (
								<option
									value={ node.name }
									key={ node.id }>
									{ node.name }</option>
							)
						} ) }
				</select>
			</div>
			<div className="form__input-container">
				<label className="form__label"  htmlFor="region">Region/State</label>
				<input className="form__input" type="text" name="region" id="region" value={ region } onChange={ e => { setRegion( e.target.value ) } } required/>
			</div>

			{ country === "United Kingdom" ? (
				<div className="form__input-container">
					<label className="form__label"  htmlFor="phoneNumber">Phone Number <span className="form__label-optional">(optional)</span></label>
					<input className="form__input" type="text" name="phoneNumber" id="phoneNumber" placeholder="Please include area code, eg +44" value={ phoneNumber } onChange={ e => { setPhoneNumber( e.target.value ) } }/>
				</div>
			) : (
				<div className="form__input-container">
					<label className="form__label"  htmlFor="phoneNumber">Phone Number <span className="form__label-optional">- required for international shipping</span></label>
					<input className="form__input" type="text" name="phoneNumber" id="phoneNumber" placeholder="Please include area code, eg +44" value={ phoneNumber } onChange={ e => { setPhoneNumber( e.target.value ) } } required/>
				</div>
			)}

			<div className="form__section-heading">
				Your Password
			</div>

			<div className="form__input-container">
				<label className="form__label" htmlFor="password">Password</label>
				<input className="form__input" type="password" name="password" id="password" value={ password } onChange={ e => { setPassword( e.target.value ) } } required/>
			</div>
			<div className={`form__input-container ${ passwordMatchError ? 'error' : '' }`}>
				<label className="form__label" htmlFor="confirmPassword">Confirm Password</label>
				<input className="form__input" type="password" name="confirmPassword" id="confirmPassword" value={ confirmPassword } onChange={ e => { setConfirmPassword( e.target.value ) } } required/>
				<span className="form__input-error">Password confirmation does not match password!</span>
			</div>

			<input type="hidden" id="" name="" value=""></input>

			{ ( error.length > 1 ) && (
				<div className="form__error-container">
					<span className="form__error-message">Sorry, we have been unable to register your details.</span>
					<span className="form__error-message">Please check your details are correct and try again.</span>
				</div>
			)}

			<p className="form__agreement">
				By creating your account, you agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link> & <Link to="/privacy-policy">Privacy Policy</Link>
			</p>

			<button className="form__submit" type="submit">Sign Up</button>
		</form>
	)
}