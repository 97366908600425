import React, { useState, useEffect, useRef } from 'react';

import useAuth from '../../hooks/useAuth'

import { RiEdit2Line, RiSave3Line } from 'react-icons/ri'

const CheckoutEmail = ( props ) => {

	const form = useRef();
	const { guest, setEmailDetails } =  props
	const { state } = useAuth()

	const [ email, setEmail ] = useState( state.user.email || '' );
	const [ editMode, setEdit ] = useState( guest );

	useEffect(()=>{
		setEmailDetails({
			contactEmail: email
		})
	}, [ editMode, setEmailDetails, email ])

	const handleFormSubmit = ( event ) => {
		event.preventDefault();
		form.current.reportValidity();
		setEdit(!editMode)
	}

	return (
		<div className="checkout-address">
			{ !editMode ? (
				<div className="checkout-address__container">	
					<div className="checkout-address__line">{email}</div>

					{ guest &&
						<button className="checkout-address__edit" onClick={() => setEdit(!editMode)}>
							<RiEdit2Line className="checkout-address__edit-icon" />
							Edit details
						</button>
					}
				</div>
			) : (
				<form className="form" onSubmit={ handleFormSubmit } ref={ form }>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="email">Email</label>
						<input className="form__input" type="email" name="email" id="email" value={ email } onChange={ e => { setEmail( e.target.value ) } } required />
					</div>

					<button className="checkout-address__save" type="submit">
						<RiSave3Line className="checkout-address__save-icon" />
						Save
					</button>
				</form>
			) }
		</div>
	)
}

export default CheckoutEmail;