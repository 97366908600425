import React, { useState } from 'react';

import { navigate, Link } from 'gatsby'
import useAuth from '../../hooks/useAuth'

export default function LoginForm( props ) {

	const { redirect, cssClass } = props

	const { login } = useAuth();
	// Login
	const [ identifier, setIdentifier ] = useState('')
	const [ password, setPassword ] = useState('')
	const [ error, setError ] = useState('')

	const handleLoginSubmit = async (event) => {
		event.preventDefault()
		try {
			await login( { identifier, password } )

			if ( redirect ) {
				navigate( redirect )
			}
		} catch( e ) {
			console.log("Error occurred during authentication")
			setError("Error")
		}
	}

	return (
		<form onSubmit={ handleLoginSubmit } className={`form ${ cssClass ? cssClass : '' }`}>
			<div className="form__input-container">
				<label className="form__label" htmlFor="username">
					Username
				</label>
				<input
					onChange={ e => { setIdentifier(e.target.value)	}}
					value={ identifier }
					className="form__input" id="username" type="text" placeholder="Username"/>
			</div>
			<div className="form__input-container">
				<label className="form__label" htmlFor="password">
					Password
				</label>
				<input
					onChange={ e => { setPassword(e.target.value) }}
					value={ password }
					className="form__input" id="password" type="password" placeholder="******************" />
			</div>

			
			{ ( error.length > 1 ) && (
				<div className="form__error-container">
					<span className="form__error-message">Sorry, we have been unable to sign you in.</span>
					<span className="form__error-message">Please check your sign in details are correct and try again.</span>
				</div>
			)}

			<Link to="/forgotten-password" className="form__forgot-password">Forgot Password?</Link>
			<button type="submit" className="form__submit">
				Sign In
			</button>
		</form>
	)
}