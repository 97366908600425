import React from 'react'
import { Link } from 'gatsby'

import useAuth from '../../hooks/useAuth'

export default function Success() {

	const { isAuthenticated } = useAuth()

	return (
		<div className="checkout">
			<div className="checkout__details-container">
				<div className="checkout__section">
					<h3 className="checkout__section-header">Your order has been processed!</h3>
					<p className="checkout__section-text">You will receive a confirmation email shortly.</p>
					{ isAuthenticated &&
						<p className="checkout__section-text">You can view you order history by going to the <Link to="/account">my account page</Link> and by clicking on <Link to="/account/orders">orders</Link>.</p>
					}
					<p className="checkout__section-text">Please direct any questions you have to hoarders by using the <Link to="/contact-us">contact page</Link>.</p>
					<p className="checkout__section-text">Thanks for shopping with us online!</p>
				</div>
			</div>
		</div>
	)
}
