import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"

import SEO from "../components/SEO/SEO"
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import CheckoutWrapper from "../components/Checkout/CheckoutWrapper"
import Success from '../components/Checkout/Success'

const CheckoutPage = ({ location }) => {

	const [ hasMounted, setHasMounted ] = useState(false)

	useEffect(() => {
		setHasMounted( true )
	}, [] )

	if ( !hasMounted ) {
		return null;
	}

	return (
		<>
			<SEO title="Checkout" />
			<Breadcrumb pathname={ location.pathname } />
			<div className="wrapper">

				<Router basepath="/checkout">
					<CheckoutWrapper default />
					<Success path="/success" />
				</Router>

			</div>
		</>
	)
}

export default CheckoutPage;