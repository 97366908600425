import React, { useState, useContext } from 'react';

import { CartContext } from '../../context/CartContext';

import CheckoutProductList from './CheckoutProductList'
import InfoCard from '../InfoCard/InfoCard'
import LoginOrGuest from './LoginOrGuest'
import Checkout from './Checkout';

import useAuth from "../../hooks/useAuth"

import { formatPrice } from '../../helpers/currency';
import { cartTotal, cartSubTotal } from '../../helpers/cart';

import { FaSpinner } from 'react-icons/fa'

const CheckoutWrapper = () => {

	const { cart, postage } = useContext( CartContext )
	const { isAuthenticated, loadingUser } = useAuth()

	const [ processing, setProcessing ] = useState()
	const [ guestCheckout, setGuestCheckout ] = useState()

	return (
		<div className="checkout">

		{ loadingUser ? (
			<>
				<div className="checkout__loading-container checkout__loading-container--loading-user">
					<FaSpinner className="loader" />
					<h3 className="checkout__loading-text">Loading</h3>
				</div>
			</>
		) : (
			<>
				{ cart && cart.length > 0 ? (
					<>
						{ isAuthenticated ? (
							<Checkout setProcessing={ setProcessing } />
						) : (
							<>
								{ guestCheckout &&
									<Checkout setProcessing={ setProcessing } guest={ true } />
								}
								{ !guestCheckout &&
									<LoginOrGuest setGuestCheckout={ setGuestCheckout } />
								}
							</>
						) }

						<div className="checkout__price-container u-hide-phablet">
							<h2 className="checkout__header">Total</h2>
							<CheckoutProductList />
							<div className="checkout__totals-container">
								<div className="checkout__totals">Subtotal: <span className="checkout__totals-price">{ formatPrice( cartSubTotal( cart ) ) }</span></div>
								<div className="checkout__totals">Delivery: <span className="checkout__totals-price">{ postage.price ? formatPrice( postage.price ) : '-' }</span></div>
								<div className="checkout__totals checkout__totals--total">Total to pay: <span className="checkout__totals-price">{ formatPrice( cartTotal( cart, postage ) ) }</span></div>
							</div>
						</div>

					</>
				) : (
					<InfoCard title="Your bag is empty" description="Add items to your bag and sign in to get shopping!" infoIcon={ false } />
				) }
			</>
		) }

		{ processing &&
			<div className="checkout__loading-container">
				<FaSpinner className="loader" />
				<h3 className="checkout__loading-text">Processing Payment</h3>
			</div>
		}

		</div>
	)
}

export default CheckoutWrapper;