import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby'

import useAuth from '../../hooks/useAuth'

import { CartContext } from '../../context/CartContext';

import { weightTotal } from "../../helpers/cart"
import { findPostage } from "../../helpers/shipping"

import { RiEdit2Line, RiSave3Line } from 'react-icons/ri'

const CheckoutDetails = ( props ) => {

	const { setDeviveryDetails } = props;

	const data = useStaticQuery(graphql`
		query shippingAddress {
			strapi {
				shippingZones {
					id
					postages {
						name
						id
						pricing
						shipping_zone {
							id
						}
					}
				}
				countries {
					name
					id
					shipping_zone {
						id
					}
				}
			}
		}`
	)

	const form = useRef();
	const { guest } =  props
	const { state } = useAuth()
	const { cart, setPostage, setShippingState } = useContext( CartContext )

	const [ firstName, setFirstName ] = useState( state.user.firstName || '' )
	const [ lastName, setLastName ] = useState( state.user.lastName  || '' )
	const [ addressLine1, setAddressLine1 ] = useState( state.user.addressLine1  || '' )
	const [ addressLine2, setAddressLine2 ] = useState( state.user.addressLine2  || '' )
	const [ city, setCity ] = useState( state.user.city  || '' )
	const [ postcode, setPostcode ] = useState( state.user.postcode  || '' )
	const [ country, setCountry ] = useState( state.user.country  || '' )
	const [ region, setRegion ] = useState( state.user.region || '' )
	const [ phoneNumber, setPhoneNumber ] = useState( state.user.phoneNumber || '' )
	const [ editMode, setEdit ] = useState( guest );

	const countries = data.strapi.countries
	const shipping = data.strapi.shippingZones

	let totalProductWeight = weightTotal( cart )

	const findCountry = useCallback((countryName) => {
		const foundCountry = countries.find(node => node.name === countryName);
		const zoneId = foundCountry.shipping_zone.id;
		findPostage(zoneId, shipping, setShippingState, totalProductWeight);
	}, [countries, shipping, setShippingState, totalProductWeight]);

	useEffect(()=>{
		setDeviveryDetails({
			shippingName: firstName + " " + lastName,
			shippingFirstName: firstName,
			shippingLastName: lastName,
			shippingAddressLine1: addressLine1,
			shippingAddressLine2: addressLine2,
			shippingCity: city,
			shippingPostcode: postcode,
			shippingCountry: country,
			shippingRegion: region,
			phoneNumber: phoneNumber,
		})
	}, [ firstName, lastName, addressLine1, addressLine2, city, postcode, country, region, phoneNumber, editMode, setDeviveryDetails ])

	useEffect(() => {
		if (country) {
			findCountry(country);
		} else {
			setPostage({ price: 0 });
		}
	}, [country, findCountry, setPostage]);

	useEffect(() => {
		if ( state?.user?.country !== 'United Kingdom' && state?.user?.phoneNumber === '' ) {
			setEdit( true )
		}
	}, [state?.user?.country, state?.user?.phoneNumber] )

	const handleCountryChange = ( event ) => {
		setCountry( event.target.value )
		setPostage({ price: 0 })
	}

	const handleFormSubmit = ( event ) => {
		event.preventDefault();
		form.current.reportValidity();
		setEdit(!editMode)
	}

	return (
		<div className="checkout-address">
			{ !editMode ? (
				<div className="checkout-address__container">
					<div className="checkout-address__line">{firstName} {lastName}</div>
					<div className="checkout-address__line">{ addressLine1 }</div>
					<div className="checkout-address__line">{ addressLine2 }</div>
					<div className="checkout-address__line">{ city }</div>
					<div className="checkout-address__line">{ postcode }</div>
					<div className="checkout-address__line">{ country }</div>
					<div className="checkout-address__line">{ phoneNumber }</div>
					<button className="checkout-address__edit" onClick={() => setEdit(!editMode)}>
						<RiEdit2Line className="checkout-address__edit-icon" />
						Edit details
					</button>
				</div>
			) : (
				<form className="form" onSubmit={ handleFormSubmit } ref={ form }>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="firstName">First Name</label>
						<input className="form__input" type="text" name="firstName" id="firstName" value={ firstName } onChange={ e => { setFirstName( e.target.value ) } } required />
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="lastName">Last Name</label>
						<input className="form__input" type="text" name="lastName" id="lastName" value={ lastName } onChange={ e => { setLastName( e.target.value ) } } required/>
					</div>

					<div className="form__input-container">
						<label className="form__label"  htmlFor="addressLine1">House No/Name</label>
						<input className="form__input" type="text" name="addressLine1" id="addressLine1" value={ addressLine1 } onChange={ e => { setAddressLine1( e.target.value ) } } required/>
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="addressLine2">Address 2 <span className="form__label-optional">(optional)</span></label>
						<input className="form__input" type="text" name="addressLine2" id="addressLine2" value={ addressLine2 } onChange={ e => { setAddressLine2( e.target.value ) } }/>
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="city">City</label>
						<input className="form__input" type="text" name="city" id="city" value={ city } onChange={ e => { setCity( e.target.value ) } } required/>
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="postcode">Postcode</label>
						<input className="form__input" type="text" name="postcode" id="postcode" value={ postcode } onChange={ e => { setPostcode( e.target.value ) } } required/>
					</div>

					<div className="form__input-container">
						<label className="form__label"  htmlFor="country">Country</label>
						<select
							className="form__input"
							type="text"
							name="country"
							id="country"
							required
							value= { country }
							onChange={ e => handleCountryChange( e ) }
							onBlur={ e => handleCountryChange( e ) }>
								{ countries.map( node => {
									return (
										<option value={ node.name }
											key={ node.id }>
											{ node.name }</option>
									)
								} ) }
						</select>
					</div>

					<div className="form__input-container">
						<label className="form__label"  htmlFor="region">Region/State</label>
						<input className="form__input" type="text" name="region" id="region" value={ region } onChange={ e => { setRegion( e.target.value ) } } required/>
					</div>

					{ country === "United Kingdom" ? (
						<div className="form__input-container">
							<label className="form__label"  htmlFor="phoneNumber">Phone Number <span className="form__label-optional">(optional)</span></label>
							<input className="form__input" type="text" name="phoneNumber" id="phoneNumber" placeholder="Please include area code, eg +44" value={ phoneNumber } onChange={ e => { setPhoneNumber( e.target.value ) } }/>
						</div>
					) : (
						<div className="form__input-container">
							<label className="form__label"  htmlFor="phoneNumber">Phone Number <span className="form__label-optional">- required for international shipping</span></label>
							<input className="form__input" type="text" name="phoneNumber" id="phoneNumber" placeholder="Please include area code, eg +44" value={ phoneNumber } onChange={ e => { setPhoneNumber( e.target.value ) } } required/>
						</div>
					)}

					{ state?.user?.country !== 'United Kingdom' && state?.user?.phoneNumber === '' &&
						<div className="form__error-container form__error-container--info">
							<p className="form__error-message">For future orders, this can be automatically filled out by adding your phone number to your <Link to="/account">account.</Link></p>
						</div>
					}

					<button className="checkout-address__save" type="submit">
						<RiSave3Line className="checkout-address__save-icon" />
						Save
					</button>
				</form>
			) }
		</div>
	)
}

export default CheckoutDetails;