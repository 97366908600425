import React, { useState, useContext } from 'react'
import { Link } from "gatsby"

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { CartContext } from '../../context/CartContext';

import StripePayment from './StripePayment'
import CheckoutDetails from './CheckoutDetails'
import CheckoutEmail from './CheckoutEmail'
import PaymentMethod from './PaymentMethod'
import CheckoutProductList from './CheckoutProductList'
import BillingDetails from './BillingDetails'
import PaypalButton from './PaypalButton'

import { formatPrice } from '../../helpers/currency';
import { cartTotal, cartSubTotal } from '../../helpers/cart';

import Shipping from "../Shipping/Shipping"

import { RiArrowRightSLine } from 'react-icons/ri'

const stripe = loadStripe( process.env.STRIPE_PK )

export default function Checkout ( props ) {

	const { cart, postage } = useContext( CartContext );

	const { setProcessing, guest } = props

	const [ deliveryDetails, setDeviveryDetails ] = useState()
	const [ emailDetails, setEmailDetails ] = useState()
	const [ billingDetails, setBillingDetails ] = useState()
	const [ paymentMethod, setPaymentMethod ] = useState()
	const [ productError, setProductError ] = useState()
	const [ internalError , setInternalError ] = useState()

	const totalPrice = cartTotal( cart, postage )

	const validDeliveryDetails = () => {
		// add billing here
		if ( !deliveryDetails || !deliveryDetails.shippingName || !deliveryDetails.shippingAddressLine1 || !deliveryDetails.shippingRegion ||
			 !deliveryDetails.shippingCountry || !deliveryDetails.shippingPostcode || !emailDetails.contactEmail ) {
			return false
		}
		
		return true
	}

	return (
		<div className="checkout__details-container">

			<h2 className="checkout__header">Checkout</h2>

			{ productError &&
				<div className="checkout__error-container">
					<p className="checkout__error-message">Error - Unfortunately 1 or more of the products in your <Link to="/cart">bag</Link> are no longer in stock. Apologies for any inconvenience.</p>
					<span className="checkout__error-product">
						<RiArrowRightSLine className="checkout__error-icon" />
						{ productError }
					</span>
				</div>
			}

			{ internalError &&
				<div className="checkout__error-container">
					<span className="checkout__error-message">
						{ internalError.message }
					</span>
					{ internalError.link && 
						<Link
						to="/contact-us"
						className="checkout__error-message">
							Contact Us 
						</Link>
					}
				</div>
			}

			<div className="checkout__section u-show-phablet">
				<h3 className="checkout__section-header">Items</h3>
				<div className="checkout__section-container">
					<CheckoutProductList />
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">Email address</h3>
				<div className="checkout__section-container">
					<CheckoutEmail setEmailDetails={ setEmailDetails } guest={ guest } />
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">Delivery Details</h3>
				<div className="checkout__section-container">
					<CheckoutDetails setDeviveryDetails={ setDeviveryDetails } guest={ guest } />
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">Billing Details</h3>
				<div className="checkout__section-container">
					<BillingDetails deliveryDetails={ deliveryDetails } billingDetails={ billingDetails } setBillingDetails={ setBillingDetails } />
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">Shipping Options</h3>
				<div className="checkout__section-container">
					<Shipping countrySelected={ true } />
				</div>
			</div>

			<div className="checkout__section u-show-phablet">
				<h3 className="checkout__section-header">Total</h3>
				<div className="checkout__section-container">
				<div className="checkout__totals-container">
					<div className="checkout__totals">Subtotal: <span className="checkout__totals-price">{ formatPrice( cartSubTotal( cart ) ) }</span></div>
					<div className="checkout__totals">Delivery: <span className="checkout__totals-price">{ postage.price ? formatPrice( postage.price ) : '-' }</span></div>
					<div className="checkout__totals checkout__totals--total">Total to pay: <span className="checkout__totals-price">{ formatPrice( totalPrice ) }</span></div>
				</div>
				</div>
			</div>

			<div className="checkout__section checkout__section--payment-method">
				<h3 className="checkout__section-header">Payment Method</h3>
				<div className="checkout__section-container">
					{ validDeliveryDetails() && deliveryDetails && postage.id && ( deliveryDetails?.shippingCountry === 'United Kingdom' || ( deliveryDetails?.shippingCountry !== 'United Kingdom' && deliveryDetails?.phoneNumber !== '' ) ) &&
						<PaymentMethod setPaymentMethod={ setPaymentMethod } />
					}
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">Payment</h3>
				<div className="checkout__section-container">
					{ deliveryDetails && paymentMethod && postage.id && !productError &&
						<>
							{ paymentMethod === 'card' &&
								<Elements stripe={ stripe }>
									<StripePayment deliveryDetails={ deliveryDetails } billingDetails={ billingDetails } emailDetails={ emailDetails } setProcessing={ setProcessing } setProductError={ setProductError } guest={ guest } setInternalError={ setInternalError } totalPrice={ totalPrice }/>
								</Elements>
							}

							{ paymentMethod === 'paypal' &&
								<div>
									<PaypalButton deliveryDetails={ deliveryDetails } billingDetails={ billingDetails } emailDetails={ emailDetails } setProcessing={ setProcessing } setProductError={ setProductError } guest={ guest } setInternalError={ setInternalError } totalPrice={ totalPrice }/>
								</div>
							}
						</>
					}
				</div>
			</div>
		</div>
	)
}