import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import useAuth from '../../hooks/useAuth'

import { RiEdit2Line, RiSave3Line } from 'react-icons/ri'

export default function BillingDetails( props ) {

	const { deliveryDetails, billingDetails, setBillingDetails } = props;

	const form = useRef();
	const { state } = useAuth()

	const [ checked, toggleChecked ] = useState( true )
	const [ editMode, setEdit ] = useState( false )
	const [ firstName, setFirstName ] = useState( state.user.firstName || '' )
	const [ lastName, setLastName ] = useState( state.user.lastName || '' )
	const [ addressLine1, setAddressLine1 ] = useState( state.user.addressLine1 || '' )
	const [ addressLine2, setAddressLine2 ] = useState( state.user.addressLine2 || '' )
	const [ city, setCity ] = useState( state.user.city || '' )
	const [ postcode, setPostcode ] = useState( state.user.postcode || '' )
	const [ country, setCountry ] = useState( state.user.country || '' )
	const [ region, setRegion ] = useState( state.user.region || '' )

	const data = useStaticQuery(graphql`
		query billingAddress {
			strapi {
				countries {
					name
					id
					shipping_zone {
						id
					}
				}
			}
		}`
	)

	const countries = data.strapi.countries

	useEffect( () => {
		if ( !deliveryDetails ) {
			return;
		}

		if ( checked ) {
			setBillingDetails({
				billingName: deliveryDetails.shippingName,
				billingFirstName: deliveryDetails.shippingFirstName,
				billingLastName: deliveryDetails.shippingLastName,
				billingAddressLine1: deliveryDetails.shippingAddressLine1,
				billingAddressLine2: deliveryDetails.shippingAddressLine2, 
				billingCity: deliveryDetails.shippingCity,
				billingPostcode: deliveryDetails.shippingPostcode,
				billingCountry: deliveryDetails.shippingCountry,
				billingRegion: deliveryDetails.shippingRegion,
			})
		}
	}, [ deliveryDetails, setBillingDetails, checked ] )

	useEffect(()=>{
		setBillingDetails({
			billingName: firstName + " " + lastName,
			billingFirstName: firstName,
			billingLastName: lastName,
			billingAddressLine1: addressLine1,
			billingAddressLine2: addressLine2, 
			billingCity: city,
			billingPostcode: postcode,
			billingCountry: country,
			billingRegion: region,
		})
	}, [ firstName, lastName, addressLine1, addressLine2, city, postcode, country, region, setBillingDetails, editMode ])

	const handleCountryChange = ( event ) => {
		setCountry( event.target.value )
	}

	const setDetailsToDelivery = () => {
		const isChecked = !checked; // due to state update delay
		toggleChecked( !checked );

		if ( isChecked ) {
			setBillingDetails({
				billingName: deliveryDetails.shippingName,
				billingFirstName: deliveryDetails.shippingFirstName,
				billingLastName: deliveryDetails.shippingLastName,
				billingAddressLine1: deliveryDetails.shippingAddressLine1,
				billingAddressLine2: deliveryDetails.shippingAddressLine2, 
				billingCity: deliveryDetails.shippingCity,
				billingPostcode: deliveryDetails.shippingPostcode,
				billingCountry: deliveryDetails.shippingCountry,
				billingRegion: deliveryDetails.shippingRegion,
			})

			setFirstName( state.user.firstName );
			setLastName( state.user.lastName );
			setAddressLine1( deliveryDetails.shippingAddressLine1 );
			setAddressLine2( deliveryDetails.shippingAddressLine2 );
			setCity( deliveryDetails.shippingCity );
			setPostcode( deliveryDetails.shippingPostcode );
			setCountry( deliveryDetails.shippingCountry );
			setRegion( deliveryDetails.shippingRegion );
		}
	}

	const handleFormSubmit = ( event ) => {
		event.preventDefault();
		form.current.reportValidity();
		setEdit(!editMode)
	}

	return (
		<div className="billing">

			<div className="checkout-address__container">
				{ billingDetails && !checked &&
					<>
					{ !editMode? (
						<>
							<div className="checkout-address__line">{ billingDetails.billingName }</div>
							<div className="checkout-address__line">{ billingDetails.billingAddressLine1 }</div>
							<div className="checkout-address__line">{ billingDetails.billingAddressLine2 }</div>
							<div className="checkout-address__line">{ billingDetails.billingCity }</div>
							<div className="checkout-address__line">{ billingDetails.billingPostcode }</div>
							<div className="checkout-address__line">{ billingDetails.billingCountry }</div>
							<button className="checkout-address__edit" onClick={() => setEdit(!editMode)}>
								<RiEdit2Line className="checkout-address__edit-icon" />
								Edit details
							</button>
						</>
					) : (
						<form className="form" onSubmit={ handleFormSubmit } ref={ form }>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="firstName">First Name</label>
								<input className="form__input" type="text" name="firstName" id="firstName" value={ firstName } onChange={ e => { setFirstName( e.target.value ) } } required />
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="lastName">Last Name</label>
								<input className="form__input" type="text" name="lastName" id="lastName" value={ lastName } onChange={ e => { setLastName( e.target.value ) } } required/>
							</div>

							<div className="form__input-container">
								<label className="form__label"  htmlFor="addressLine1">House No/Name</label>
								<input className="form__input" type="text" name="addressLine1" id="addressLine1" value={ addressLine1 } onChange={ e => { setAddressLine1( e.target.value ) } } required/>
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="addressLine2">Address 2</label>
								<input className="form__input" type="text" name="addressLine2" id="addressLine2" value={ addressLine2 } onChange={ e => { setAddressLine2( e.target.value ) } }/>
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="city">City</label>
								<input className="form__input" type="text" name="city" id="city" value={ city } onChange={ e => { setCity( e.target.value ) } } required/>
							</div>
							<div className="form__input-container">
								<label className="form__label"  htmlFor="postcode">Postcode</label>
								<input className="form__input" type="text" name="postcode" id="postcode" value={ postcode } onChange={ e => { setPostcode( e.target.value ) } } required/>
							</div>

							<div className="form__input-container">
								<label className="form__label"  htmlFor="country">Country</label>
								<select
									className="form__input"
									type="text"
									name="country"
									id="country"
									required
									value= { country }
									onChange={ e => handleCountryChange( e ) }
									onBlur={ e => handleCountryChange( e ) }>
										{ countries.map( node => {
											return (
												<option value={ node.name }
													key={ node.id }>
													{ node.name }</option>
											)
										} ) }
								</select>
							</div>

							<div className="form__input-container">
								<label className="form__label"  htmlFor="region">Region/State</label>
								<input className="form__input" type="text" name="region" id="region" value={ region } onChange={ e => { setRegion( e.target.value ) } } required/>
							</div>

							<button className="checkout-address__save" type="submit">
								<RiSave3Line className="checkout-address__save-icon" />
								Save
							</button>
						</form>
					) }
				</>
				}
			</div>

			<div className="billing__checkbox-container">
				<input
					className="billing__checkbox"
					type="checkbox" id="billingAddress"
					defaultChecked={ checked }
					onChange={ () => { setDetailsToDelivery() } }/>
				<label className="billing__label" htmlFor="billingAddress">Use delivery address as billing address</label>
			</div>
		</div>
	)
}