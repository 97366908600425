import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import axios from 'axios'
import scriptLoader from "react-async-script-loader";
import { useStaticQuery, navigate, graphql } from 'gatsby'

import useAuth from '../../hooks/useAuth'
import { CartContext } from '../../context/CartContext'
import { GTPurchase } from '../../helpers/gtagEvents'
import { cartTotal } from '../../helpers/cart'

const CLIENT_ID = process.env.PAYPAL_CLIENT_ID;

let PayPalButton = null;

export const PaypalButton = ( props ) => {

	const [ loading, setLoading ] = useState( true )
	const [ showButtons, setShowButtons ] = useState( false )
	const { state } = useAuth()
	const { user } = state 
	const { billingDetails, deliveryDetails, emailDetails, setProcessing, isScriptLoaded, isScriptLoadSucceed, guest, setProductError, setInternalError, totalPrice } = props
	const { cart, clearCart, postage } = useContext( CartContext );

	const data = useStaticQuery(graphql`
		query paypalCountries {
			strapi {
				countries {
					name
					paypalCountryCode
				}
			}
		}`
	)

	const countries = data.strapi.countries

	const findCountryCode = ( countryName ) => {
		const foundCountry = countries.find( node => node.name === countryName)
		const countryCode = foundCountry.paypalCountryCode
		
		return countryCode
	}

	const billingCountryCode = findCountryCode( billingDetails.billingCountry )
	const shippingCountryCode = findCountryCode( deliveryDetails.shippingCountry )

	useEffect( () => {

		const loadToken = async () => {

			const data = {
				cart,
				postageId: postage.id,
				...deliveryDetails,
				shippingCountryCode,
				totalPrice
			}
	
			let payload;
			if ( guest ){
				payload = await axios.post( '/api/orders/guest/payment/paypal', data )
				payload = payload.data
			} else{
				payload = await axios.post( '/api/orders/payment/paypal', data )
				payload = payload.data
			}

			switch ( payload.status ) {
				case 402:
					setProductError( payload.product )
					return;
				case 418:
					const errorString = payload.message
					setInternalError( { message: errorString, link: false } )
					window.scrollTo( 0, 0 )
					return;
				case 401:
					const errorString1 = "There seems to be a problem finding your details, please can you log out and try again."
					setInternalError( { message: errorString1, link: false } )
					window.scrollTo( 0, 0 )
					return;
				case 500: 
					const error500 = "There seems to be a problem setting up your order, please can you remove the items from your bag and try again"
					setInternalError( { message: error500, link: false } )
					window.scrollTo( 0, 0 )
					return;
				default:
					break;
			}

			if ( isScriptLoaded && isScriptLoadSucceed ) {
				PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
				setShowButtons( true )
				setLoading ( false )
			}
	
			return payload
		}

		if ( postage.id ) {
			loadToken();
		}

	},[ isScriptLoaded, isScriptLoadSucceed, cart, deliveryDetails, guest, postage.id, setProductError, shippingCountryCode, setInternalError, totalPrice ])

	const loadToken = async () => {

		const data = {
			cart,
			postageId: postage.id,
			...deliveryDetails,
			shippingCountryCode,
			totalPrice
		}

		let payload;
		if ( guest ){
			payload = await axios.post( '/api/orders/guest/payment/paypal', data )
			payload = payload.data
		} else{
			payload = await axios.post( '/api/orders/payment/paypal', data )
			payload = payload.data
		}

		switch ( payload.status ) {
			case 402:
				setProductError( payload.product )
				return;
			case 418:
				const errorString = payload.message
				setInternalError( { message: errorString, link: false } )
				window.scrollTo( 0, 0 )
				return;
			case 401:
				const errorString1 = "There seems to be a problem finding your details, Please can you log out and try again."
				setInternalError( { message: errorString1, link: false } )
				window.scrollTo( 0, 0 )
				return;
			case 500: 
					const error500 = "There seems to be a problem setting up your order, please can you remove the items from your bag and try again"
					setInternalError( { message: error500, link: false } )
					window.scrollTo( 0, 0 )
					return;
			default:
				break;
		}

		return payload
	}

	const createOrder = async (data, actions) => {

		const result = await loadToken()

		return actions.order.create({
			purchase_units: [
				result
			],
			payer: {
				name: {
					given_name: billingDetails.billingFirstName,
					surname: billingDetails.billingLastName
				  },
				email_address: emailDetails.contactEmail,
				address: {
					address_line_1: billingDetails.billingAddressLine1,
					address_line_2: billingDetails.billingAddressLine2,
					admin_area_2: billingDetails.billingCity,
					admin_area_1: billingDetails.billingRegion,
					postal_code: billingDetails.billingPostcode,
					country_code: billingCountryCode
				}
			}
		});
	};

	const onApprove = (data, actions) => {
		setProcessing( true )

		actions.order.capture().then( async details => {

			if ( details.status === "COMPLETED" ) {
				// The payment has been processed!
				const orderData = {
					...deliveryDetails,
					...billingDetails,
					...emailDetails,
					paymentIntent: details,
					cart,
					postageId: postage.id,
					user,
					guestCheckout: guest
				}

				let payload
				if ( guest ){
					payload = await axios.post( '/api/order/guest/create/paypal', orderData );
				} else{
					payload = await axios.post( '/api/order/create/paypal', orderData );
				}
				payload = payload.data

				if( payload.status === 500 ) {
					setInternalError( { messgae: payload.message, link: true } )
					setProcessing( false )
					setShowButtons ( false )
					window.scrollTo( 0, 0 )
					return
				}
		
				navigate( '/checkout/success' )

				const gtData = {
					transactionId: details.id,
					value: parseFloat( cartTotal( cart, postage ) ),
					postagePrice: postage.price ? parseFloat( postage.price ) : 0
				}

				GTPurchase( cart, gtData )
				clearCart()
				setProcessing( false );
			}
			setShowButtons ( false )
		});
	};

	const onError = (err) => {
		// Show an error page here, when an error occurs
		console.log( err )
	}

	return (
		<>
			{
				<div>
					{loading && <p>Loading</p>}
					{showButtons && (
						<PayPalButton
							createOrder={(data, actions) => createOrder(data, actions)}
							onApprove={(data, actions) => onApprove(data, actions)}
							onError = { (err) => onError( err )}
						/>
					)}
				</div>
			}
		</>
	)
}

export default scriptLoader(`https://www.paypal.com/sdk/js?currency=GBP&client-id=${CLIENT_ID}&disable-funding=card,sofort`)(PaypalButton);