import React from 'react'

import { RiErrorWarningLine, RiShoppingBagLine } from 'react-icons/ri'

export default function InfoCard ( props ) {

	const { description, title, infoIcon = true } = props

	return (
		<div className="info-card">

			{ infoIcon ? (
				<RiErrorWarningLine className="info-card__icon" />
			) : (
				<RiShoppingBagLine className="info-card__icon" />
			) }

			<div className="info-card__content">
				{ title ? (
					<h2 className="info-card__heading">{ title }</h2>
				) : (
					<h2 className="info-card__heading">Welcome to our new site</h2>
				) }

				{ description ? (
					<div className="info-card__description">
						{ description }
					</div>
				) : (
					<div className="info-card__description">
						If you were an existing customer on our previous site you will need to create a new account to make an order, as we have now changed systems. We apologise for any inconvenience but hope to have improved your shopping experience!
					</div>
				)}
			</div>
		</div>
	)
}