import React, { useState, useLayoutEffect } from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { RiArrowRightSLine } from 'react-icons/ri'

export default function Breadcrumbs( props ) {

	const pathName = props.pathname;
	const [ breadcrumbs, setBreadcrumbs ] = useState([]);

	const createBreadcrumbs = ( fullPath ) => {
		const home = {
			path: '/',
			name: 'Home'
		}
		const paths = [ home ];

		fullPath.split('/').reduce( ( prev, curr ) => {
			const currPath = `${prev}/${curr}`;
			const name = curr.replace( /-/g, ' ' );

			const breadcrumb = {
				path: currPath,
				name
			}

			paths.push(breadcrumb);
			return currPath;
		});

		return paths;
	}

	useLayoutEffect( () => {
		const newBreadcrumbs = createBreadcrumbs( pathName );
		setBreadcrumbs( newBreadcrumbs );
	}, [ pathName ])

	return (
		<div className="wrapper">
			<div className="breadcrumbs">
				<ul className="breadcrumbs__list">

					{ breadcrumbs.map( ( crumb, index ) => (
						<li className="breadcrumbs__list-item" key={ index }>
							<Link to={ crumb.path } className="breadcrumbs__list-item-link" activeClassName="active">
								{ crumb.name }
							</Link>
							{ index + 1 !== breadcrumbs.length &&
								<RiArrowRightSLine className="breadcrumbs__list-icon" />
							}
						</li>
					) ) }
				</ul>
			</div>
		</div>
	)
}

Breadcrumbs.propTypes = {
	pathname: PropTypes.string,
}
