export const findPostage = ( zoneId, shipping, setState, totalProductWeight ) => {

	if ( !zoneId ) return

	let filteredPostage = [];
	let filteredShipping = shipping.filter( node => {
		return node.id === zoneId
	})

	filteredShipping[0].postages.forEach( postage => {
		const object = postage.pricing.find(pricing => pricing.weight >= totalProductWeight)
		if( object ) {
			const config = {
				id:postage.id,
				name: postage.name,
				...object
			}

			filteredPostage.push( config )
		}
	})

	setState({
		zoneId,
		filteredPostage
	})
}
